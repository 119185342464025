<template>
  <div id="categories">

    <Loading v-if="loading" />

    <section class="site-section" v-else>
      <div class="container-fluid">
        <div class="section-header">
          <h2 class="section-title mb-3" v-text="searchField" />
        </div>

        <div class="section-body">
          <div class="products-container">
            <div class="row carts-row">
              <div :class="['col-12', 'col-md-9', {'offset-md-1': !showFilter}]">
                <div class="alert alert-warning" v-if="productsIsEmpty" v-text="'לא נמצאו מוצרים'" />

                <div class="row row-cols-2 row-cols-md-4 row-cols-lg-3 list-products" v-else>
                  <div class="col-6 col-md-4 col-lg-3" v-for="(product, index) of products" :key="index">
                    <CardProduct :product="product" :info="true" />
                  </div>
                </div>
              </div>

              <div :class="['col-12', {'col-md-3': showFilter, 'col-md-2': !showFilter}]">
                <FilterComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import { eventEmitter } from '@/main'
import CardProduct from '@/components/CardProduct'
import FilterComponent from '@/components/categories/FilterComponent'

export default {
  name: 'Search',

  components: {
    CardProduct, FilterComponent,
  },

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME+' | '+this.searchField+' | '+process.env.VUE_APP_NAME_HEB
    }
  },

  data() {
    return {
      device,
      loading: true,
      productsIsEmpty: false,
      showFilter: false,
    }
  },

  computed: {
    color() {
      return this.$store.getters.color
    },
    price() {
      return this.$store.getters.price
    },
    products() {
      return this.$store.getters.searchProducts
    },
    searchField() {
      return this.$store.getters.searchField
    },
    filteredProducts() {
      return this.$store.getters.filteredProducts
    },
  },

  watch: {
    color(newColor) {
      let filteredProducts = newColor
        ? this.filteredProducts.filter(product => product.type !== 'banner').filter(product => product.colors.findIndex(c => c.id === newColor.id) !== -1)
        : this.filteredProducts

      if(this.price)
        filteredProducts = filteredProducts.filter(product => product.webPriceHEB >= this.price[0] && product.webPriceHEB <= this.price[1])

      this.commitProducts(filteredProducts)
    },
    price(newPrice) {
      let filteredProducts = newPrice
        ? this.filteredProducts.filter(product => product.type !== 'banner').filter(product => product.webPriceHEB >= newPrice[0] && product.webPriceHEB <= newPrice[1])
        : this.filteredProducts

      if(this.color)
        filteredProducts = filteredProducts.filter(product => product.colors.findIndex(c => c.id === this.color.id) !== -1)

      this.commitProducts(filteredProducts)
    },
    products(newProducts) {
      this.productsIsEmpty = newProducts.length === 0
    },

    async searchField(newSearchField) {
      if(device.mobile() && newSearchField !== '') {
        this.loading = true
        await this.$store.dispatch('getSearchProducts', newSearchField)
        this.loading = false
      } else {
        this.$store.commit('setFilteredProducts', [])
        this.$store.commit('setSearchProducts', [])
        this.loading = false
      }
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    commitProducts(filteredProducts) {
      this.$store.commit('setSearchProducts', filteredProducts)
      this.productsIsEmpty = filteredProducts.length === 0
    }
  },

  async created() {
    await this.$store.dispatch('getColors', 0)

    if(this.searchField !== '') {
      await this.$store.dispatch('getSearchProducts', this.searchField)
    }

    this.productsIsEmpty = this.products.length === 0
    this.loading = false

    eventEmitter.$on('onShowFilter', value => this.showFilter = value)
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.new-range{
  width: 200px;
  text-align: center;
  position: relative;
}

.new-range .my-range-input {
  position: absolute;
  left: 0;
  bottom: 0;
}
.my-range-input::-webkit-slider-thumb{
  z-index: 2;
  position: relative;
  top: 2px;
  margin-top: -7px;
}
.my-range{
  position: relative;
  width: 300px;
}
.range-left{
  position: absolute;
  left: 0;
  top: -2px;
  width: .5rem;
  height: .5rem;
  padding: 0;
  background-color: #000;
  border-radius: 50%;
  border: none;
  z-index: 2;
}
.range-right{
  position: absolute;
  right: 0;
  top: -2px;
  width: .5rem;
  height: .5rem;
  padding: 0;
  background-color: #000;
  border-radius: 50%;
  border: none;
  z-index: 2;
}
.process{
  position: absolute;
  left: 0;
  right: 0;
  height: .15rem;
  background-color: #d7d7d7;
  z-index: 1;
}
#categories .container-fluid{
  padding-left: 6.8rem;
  padding-right: 2rem;
}
#categories .site-section{
  margin-top: 15px;
}
.sorting{
  margin-bottom: 0.8rem;
}
.products-container > .row > .offset-md-1 {
  margin-left: 7.8%;
}
.products-container > .row > .col-md-9{
  flex: 0 0 81.3%;
  max-width: 81.3%;
}
.products-container > .row > .col-md-2 {
  flex: 0 0 10.766667%;
  max-width: 10.766667%;
}
.products-container > .row > .col-md-3 {
  flex: 0 0 18.7%;
  max-width: 18.7%;
}
.products-container .col-md-9 .row .col-md-3{
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.list-products{
  direction: rtl;
}
.alert{
  text-align: center;
  margin-top: 3rem;
}
.section-footer{
  display: flex;
  justify-content: center;
}
.banner-photo{
  margin-left: -3px;
  margin-right: -2px;
}

@include media-breakpoint-down(sm) {
  #categories .site-section{
    margin-top: 1.4rem;
  }
  #innerLayout .section-header{
    margin-bottom: .9rem;
  }
  #innerLayout .section-title{
    font-size: 1.3rem;
  }
  #categories .container-fluid{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  #categories .row{
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  #categories .col-6,{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .products-container{
    display: flex;
    flex-direction: column;
  }
  .products-container > .row{
    flex-direction: column-reverse;
  }
  .products-container > .row > .col-12{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .products-container > .row > .offset-md-1{
    margin-left: 0;
  }
  .products-container .col-md-3{
    margin-bottom: 0;
  }
}
</style>
